<template>
  <div class="content-layout">
    <div class="property-operations">
      <div>
        <a-button
          v-if="$hasPermissions(['rcs:entry_i18n_language:create'])"
          type="primary"
          class="add-btn"
          @click="handleCreator()"
          >新增</a-button
        >
      </div>
      <div class="data-filter">
        <!-- <a-input
          clearable
          placeholder="请输入标识符或备注内容"
          v-model="requestParams.searchKey"
          class="search-keyword"
          @keyup.enter.native="doSearch"
        ></a-input>
        <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button> -->
      </div>
    </div>
    <a-table
      size="middle"
      :pagination="false"
      :columns="this.$constRepository.language.columns"
      :data-source="tableDate"
      class="property-table"
      rowKey="id"
    >
      <template slot="createdTime" slot-scope="scope">{{ moment(scope).format('YYYY年MM月DD日') }}</template>
      <template slot="lastModificationTime" slot-scope="scope">{{ moment(scope).format('YYYY年MM月DD日') }}</template>
      <template slot="action" slot-scope="scope">
        <a-button
          v-if="$hasPermissions(['rcs:entry_i18n_language:update'])"
          class="green-btn"
          ghost
          @click="handleEdit(scope)"
          >编辑</a-button
        >
        <a-divider type="vertical" />
        <a-button v-if="$hasPermissions(['rcs:entry_i18n_language:delete'])" class="red-btn" ghost>
          <a-popconfirm okText="是" cancelText="否" title="是否删除语言?" @confirm="() => onDelete(scope)">
            <a href="javascript:;">删除</a>
          </a-popconfirm>
        </a-button>
      </template>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
    <language-form-modal ref="languageFormModal" />
  </div>
</template>

<script>
import moment from 'moment';
import LanguageFormModal from './components/LanguageFormModal';

export default {
  name: 'LanguageManagement',
  components: { LanguageFormModal },
  activated() {
    this.loadedPageData();
  },
  data() {
    return {
      searchKeyword: '',
      requestParams: {},
      tableDate: [],
      moment,
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
    };
  },
  methods: {
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async listLanguages() {
      const params = {
        searchKey: this.requestParams.searchKey,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.language.getLanguages(params);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    async loadedPageData() {
      await this.listLanguages();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listLanguages();
      }
    },
    handleCreator() {
      this.$refs.languageFormModal.showCreateDialog((languageForm) => {
        this.$apiManager.language.createLanguage(languageForm).then(() => {
          this.$message.success('创建成功');
          this.loadedPageData();
        });
      });
    },
    handleEdit(record) {
      this.$refs.languageFormModal.showUpdateDialog(record, (languageForm) => {
        this.$apiManager.language.updateLanguage(languageForm).then(() => {
          this.$message.success('编辑成功');
          this.loadedPageData();
        });
      });
    },
    onDelete(record) {
      this.$apiManager.language.deleteLanguage(record.id).then(() => {
        this.$message.success('删除成功');
        this.loadedPageData();
      });
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.property-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    // margin: 10px 0px;
    // margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      // margin: 10px 0px;
      margin-left: 3%;
    }
  }

}
.property-table {
    margin-top: 1%;
  }
.pagination {
  margin-top: 3%;
}
</style>
